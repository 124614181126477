import React from 'react'
import { Link } from 'gatsby';
import { graphql, navigate, StaticQuery } from 'gatsby'
import './home.css'

export default () => (
   <StaticQuery
     query={graphql` 
 query HomeQuery {
   allContentfulBlog(
       limit: 9
       sort: { fields: [createdAt], order: DESC }
       filter: {
       node_locale: {eq: "es",}
       home: {eq: true}
     }
     ) {
     edges {
       node {
         id
         slug
         title
         category {
             title
             id
             slug
         }
         featuredImage {
           fluid(maxWidth: 1200, quality: 85) {
             src
             ...GatsbyContentfulFluid
           }
         }
       }
     }
   }
 }
`}
     render={data => (
        <div className='feed'>
            {data.allContentfulBlog.edges.map(edge => (
                <Link key={edge.node.id} className='card' style={{
                  backgroundImage: `linear-gradient(
                  to bottom, 
                  rgba(10,10,10,0) 0%,
                  rgba(10,10,10,0) 50%,
                  rgba(10,10,10,0.7) 100%),
                  url(${edge.node.featuredImage.fluid.src})`
              }} to={`/blog/${edge.node.slug}`}>
            {edge.node.category.map(category => ( <p key={category.id} className='card__category'>{category.title}</p>  ))}
            <h3 className='card__title'>{edge.node.title}</h3>
            </Link>
            ))}
        </div>
     )}
   />
 )